<template>
    <div class="p-2 m-0" v-if="true">
        <div class="e-top">
            <search></search>
            <!-- <button class="btn btn-sm btn-success w-100">添加供应商</button> -->
        </div>
        <div class="bg-white radius-5 w-100 p-2 px-3 my-2">
            <div class="d-flex border-bottom pb-1">
                <div class="flex-grow-1">陈一一</div>
                <div class="bd-highlight text-muted">区域: 苏州</div>
            </div>
            <div class="pt-1 d-flex justify-content-around">
                <div class="text-center">
                    <p class="m-0 fs-9">1122</p>
                    <p class="m-0 fs-9">总报道</p>
                </div>
                <div class="text-center">
                    <p class="m-0 fs-9">1122</p>
                    <p class="m-0 fs-9">入 职</p>
                </div>
                <div class="text-center">
                    <p class="m-0 fs-9">1122</p>
                    <p class="m-0 fs-9">在 职</p>
                </div>
                <div class="text-center">
                    <p class="m-0 fs-9">1122</p>
                    <p class="m-0 fs-9">离 职</p>
                </div>
            </div>
            <div class="py-1">
                <p class="m-0 text-muted">联系人: 陈一对</p>
                <p class="m-0 text-muted">性 质: 个人</p>
                <p class="m-0 text-muted">门店地址: 苏州</p>
            </div>
            <div class="d-flex justify-content-around py-1 bg-light">
                <!-- <div class="text">修 改</div>
                <div class="text">删 除</div> -->
                <!-- <div class="text">人员管理</div> -->
            </div>
        </div>

    </div>
    <!-- 添加 -->
</template>

<script>
export default {
    name: "Provider",
    setup() {

        return {

        }
    }
}
</script>

<style>

</style>